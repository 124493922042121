import { useMemo } from 'react'

function useFormattedUrl(url: string) {
    return useMemo(() => {
        if (!url) {
            return ''
        }

        const formattedUrl = url.match(/^(http|https):\/\//) ? url : `https://${url}`

        try {
            new URL(formattedUrl)
            return formattedUrl
        } catch (err) {
            return ''
        }
    }, [url])
}

export default useFormattedUrl
